/**
 * @generated SignedSource<<8d0b9fae8295d0fe2bf9d05b06da598f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "Literal",
  "name": "roles",
  "value": [
    "admin",
    "editor",
    "author"
  ]
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "databaseId",
    "storageKey": null
  },
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "alias": "administratorUsers",
  "args": [
    {
      "kind": "Literal",
      "name": "attribute",
      "value": "administrator_job_title_id"
    },
    (v2/*: any*/)
  ],
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "users",
  "plural": true,
  "selections": (v6/*: any*/),
  "storageKey": "users(attribute:\"administrator_job_title_id\",roles:[\"admin\",\"editor\",\"author\"])"
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "JobTitleConnection",
  "kind": "LinkedField",
  "name": "jobTitles",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "JobTitle",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": (v6/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": [
    (v2/*: any*/)
  ],
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "users",
  "plural": true,
  "selections": (v6/*: any*/),
  "storageKey": "users(roles:[\"admin\",\"editor\",\"author\"])"
},
v10 = [
  {
    "kind": "TypeDiscriminator",
    "abstractKey": "__isNode"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewResourceLocalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Resource",
        "kind": "LinkedField",
        "name": "resource",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ResourceForm_resource"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewResourceLocalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Resource",
        "kind": "LinkedField",
        "name": "resource",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "administratorIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "administratorJobTitleId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "availability",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ciaExplanation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "confidentiality",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "informationSourceIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "integrity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jobTitleId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "manualParagraphIds",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "normParagraphIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "normRequirementIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organisationId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ownerId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rpo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rto",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "supplierIds",
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": (v10/*: any*/),
            "type": "UsersInterface",
            "abstractKey": "__isUsersInterface"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "CiaFields",
            "abstractKey": "__isCiaFields"
          },
          {
            "kind": "InlineFragment",
            "selections": (v10/*: any*/),
            "type": "JobTitlesInterface",
            "abstractKey": "__isJobTitlesInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "00fc829cf7cb114de4efe1449360f940",
    "id": null,
    "metadata": {},
    "name": "NewResourceLocalQuery",
    "operationKind": "query",
    "text": "query NewResourceLocalQuery(\n  $id: ID!\n) {\n  resource(id: $id) {\n    administratorUsers: users(attribute: \"administrator_job_title_id\", roles: [\"admin\", \"editor\", \"author\"]) {\n      __typename\n      databaseId\n      name\n      id\n    }\n    jobTitles {\n      nodes {\n        __typename\n        databaseId\n        name\n        id\n      }\n    }\n    users(roles: [\"admin\", \"editor\", \"author\"]) {\n      __typename\n      databaseId\n      name\n      id\n    }\n    ...ResourceForm_resource\n    id\n  }\n}\n\nfragment Administrators_usersInterface on UsersInterface {\n  __isUsersInterface: __typename\n  administratorUsers: users(attribute: \"administrator_job_title_id\", roles: [\"admin\", \"editor\", \"author\"]) {\n    databaseId\n    name\n    id\n  }\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n}\n\nfragment CiaForm_ciaFields on CiaFields {\n  __isCiaFields: __typename\n  __typename\n}\n\nfragment OwnerFilter_usersInterface_4rSJYf on UsersInterface {\n  __isUsersInterface: __typename\n  users(roles: [\"admin\", \"editor\", \"author\"]) {\n    databaseId\n    name\n    id\n  }\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n}\n\nfragment ResourceForm_jobTitlesInterface on JobTitlesInterface {\n  __isJobTitlesInterface: __typename\n  jobTitles {\n    nodes {\n      databaseId\n      name\n      id\n    }\n  }\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n}\n\nfragment ResourceForm_resource on Resource {\n  administratorIds\n  administratorJobTitleId\n  availability\n  ciaExplanation\n  confidentiality\n  informationSourceIds\n  integrity\n  jobTitleId\n  manualParagraphIds\n  name\n  normParagraphIds\n  normRequirementIds\n  organisationId\n  ownerId\n  rpo\n  rto\n  supplierIds\n  ...Administrators_usersInterface\n  ...CiaForm_ciaFields\n  ...OwnerFilter_usersInterface_4rSJYf\n  ...ResourceForm_jobTitlesInterface\n}\n"
  }
};
})();

node.hash = "73d143df902e3e58516734254b7fa239";

module.exports = node;
